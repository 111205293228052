<template>
  <div class="flex-column" style="height: 100vh;">
    <van-nav-bar title="煎配记录" left-text="返回" @click-left="back" left-arrow />

    <van-tabs
      class="flex1 tabs100"
      type="card"
      style="background:#fff;"
      title-active-color="#fff"
      color="#20a0ff"
    >
      <van-tab
        v-if="$user && $user.processConfig && $user.processConfig.isShowProcess"
        title="煎配流程"
      >
        <van-pull-refresh
          style="height: 100%;overflow: auto;"
          v-model="isLoading"
          @refresh="startQuery"
        >
          <div
            v-if="errorMsg != null || !processList || processList.length === 0"
            style="text-align:center;margin:10px;padding: 1em;"
          >{{errorMsg || '没有煎配记录'}}</div>
          <van-steps direction="vertical" :active="0">
            <van-step v-for="item in processList" :key="item.$index">
              <h3>{{item.state}}</h3>
              <p>{{item.time}}</p>
            </van-step>
          </van-steps>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="物流追踪">
        <van-pull-refresh
          style="height: 100%;overflow: auto;"
          v-model="isLoading"
          @refresh="startQuery"
        >
          <div
            v-if="carryName == '厂车'"
            style="text-align:center;padding:10px;font-weight: bold;background:#fff;"
          >自取</div>
          <div
            v-if="carryName == '厂车'"
            style="text-align:center;padding:10px;background:#fff;margin-top:10px;"
          >自取处方无物流记录</div>

          <div
            v-if="carryName != '厂车'"
            style="text-align:center;padding:10px;font-weight: bold;background:#fff;"
          >{{carryName}}快递:{{logisticsCode}}</div>
          <div
            v-if="carryName != '厂车' && trackList.length == 0"
            style="text-align:center;padding:10px;background:#fff;margin-top:10px;"
          >暂无物流记录</div>

          <van-steps v-if="carryName != '厂车'" direction="vertical" :active="0">
            <van-step v-for="item in trackList" :key="item.$index">
              <h3>{{item.state}}</h3>
              <p>{{item.time}}</p>
            </van-step>
          </van-steps>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carryName: "",
      logisticsCode: "",
      errorMsg: null,
      processList: [],
      trackList: [],
      list: [],
      show: false,
      title: "标题",
      isLoading: false,
    };
  },
  created() {},
  methods: {
    // 返回
    back() {
      this.$back();
    },
    startQuery() {
      this.errorMsg = null;
      this.$axios
        .get("/data/getRecipeState", {
          params: {
            way: this.$route.query.way,
            queryParam: this.$route.query.queryParam,
            userId: this.$user.id,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code == "ok") {
            res.data = res.data || {};
            console.log(res.data);
            this.processList =
              (res.data.processState && res.data.processState.reverse()) || [];
            this.trackList = res.data.logisticsInfo || [];
            this.carryName = res.data.carryName;
            this.logisticsCode = res.data.logisticsCode;
            console.log(this);
          } else {
            this.errorMsg = res.msg;
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
  },

  mounted() {
    this.startQuery();
  },
};
</script>

<style lang="less" scoped>
.one_report {
  margin-bottom: 15px;
}

.form_text {
  margin: 15px 0 30px;
  padding: 0 12px;
}

.form_btn {
  text-align: right;
}

.form_btn button {
  margin-left: 10px;
}

.one_report_data {
  margin-bottom: 20px;
  padding: 0 22px;
  div {
    line-height: 30px;
  }
}
.item_container {
  width: 100%;
  overflow: hidden;
}
.item_container span {
  display: block;
  float: left;
}
</style>
<style lang="less">
.van-nav-bar .van-icon,
.van-nav-bar__text {
  color: #20a0ff;
}
</style>
